import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ToastContainer } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';
import FrontendObservability from '../components/frontend-observability';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <FrontendObservability />
      <ToastContainer />
      <Component {...pageProps} />
    </>
  )
}
